


// import logo from './logo.svg';
// import './App.css';

import "./SlideCarouselFiltered.css";
import { useEffect, useRef, useState } from "react";
import { animate, motion, useMotionValue, useScroll } from "framer-motion";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// import img1 from "../Images/Alcon1.jpg"
// import img2 from "../Images/Alcon7.jpg"
import { Link } from "react-router-dom";
import img2 from "../../Components/images/img1.jpg"
import img3 from "../../Components/images/img1.jpg"
import { faFontAwesome, faMarkdown } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { fab,faFacebook, faTwitter,faInstagram,faYoutube, faLinkedin, faShuttleVan, faPeopleCarry, faHamburger, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import {faChartLine,faClock,faStar,faTools,faUserShield ,faTimes,faBars, faMapMarker } from '@fortawesome/free-solid-svg-icons'

// import img1 from "../Images/Alcon1.jpg"
// import img1 from "../Images/Alcon1.jpg"
// import img1 from "../Images/Alcon1.jpg"

export default function SlideCarousel({Heading, CarouselData, filter}) {
 

   


  const ref = useRef(null);
  const { scrollXProgress } = useScroll({ container: ref });
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [trackMouse, setTrackMouse] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(true);
  const [items, setItems] = useState([]);
  const loadholder = [1,1,1,1,1]

  useEffect(() => {
    const arr = new Array(10).fill(undefined).map((val, idx) => idx);
    setItems(arr);
   if(CarouselData){
    console.log("filter data",CarouselData)
    console.log("filter here",filter)
   }
   
  }, []);

  const x = useMotionValue(0);

  const handleMouseMove = (e) => {
    if (!ref.current) return;
    if (!trackMouse) return;

    setAnimationComplete(false);

    const xVal = e.pageX - ref.current.offsetLeft;
    const walk = (xVal - startX) * 2; //scroll-fast

    const controls = animate(x, scrollLeft - walk, {
      type: "tween",
      ease: "easeOut",
      duration: 0.5,
      onUpdate: (val) => {
        if (!ref.current) return;
        ref.current.scrollLeft = val;
      },
      onComplete: () => {
        setAnimationComplete(true);
      },
      onStop: () => {
        setAnimationComplete(true);
      }
    });
    return controls.stop;
  };
// dsds

  

  const handleMouseDown = (e) => {
    if (!ref.current) return;

    setTrackMouse(true);

    const startX = e.pageX - ref.current.offsetLeft;
    setStartX(startX);

    const scrollLeft = ref.current.scrollLeft;
    setScrollLeft(scrollLeft);
  };

  const handleMouseLeave = () => {
    setTrackMouse(false);
  };

  const handleMouseUp = () => {
    setTrackMouse(false);
  };

  const handleScroll = () => {
    if (!ref.current) return;

    if (animationComplete) {
      x.set(ref.current.scrollLeft);
    }
  };

  const cardData = [ 
    // { 
    //    ImgUrl: img1,
    //    Name: "Snowflake Chartels", 
    //    Title: "Nike Converse",
    //    Price: 300
 
    // },
    { 
       ImgUrl: img2,
       Name: "Zed Watch Guy", 
       Title: "Casio Classic Watch",
       Price: 250
 
    }, 
     { 
       ImgUrl: img3,
       Name: "Snowflakes Chartel", 
       Title: "Airforce Converse",
       Price: 300
 
    }, 
    //  { 
    //    ImgUrl: img1,
    //    Name: "Snowflakes Chartel", 
    //    Title: "Airforce Converse",
    //    Price: 300
 
    // }, 
 
 
   ]

  return (
    <>  

    <div className="carouselCover" id="Iggy">

        <div className="Prewording">

                <h2>{Heading}</h2>

        </div>


    
      <svg id="progress" width="100" height="100" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="30" pathLength="1" className="bg" />
        <motion.circle
          cx="50"
          cy="50"
          r="30"
          pathLength="1"
          className="indicator"
          style={{ pathLength: scrollXProgress }}
        />
      </svg>
      <motion.ul
        ref={ref}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        onScroll={handleScroll}
        className="carouselList"
      >
        {CarouselData ? CarouselData

          
  
  //.filter((item,index) => item?.store?.content[0]?.data?.target?.fields?.name == filter)  // Add your filter here
  .filter((item,index) => item?.storeid == filter)  // Add your filter here
  .map((item, index) => (
    <motion.li onClick={() => { console.log("have been clicked", item) }} key={index}>
      <Link className="textLink" to={`/productOrder/${item.id}`}>
        <div className="ListingDisplayCover">
          <div className="ListingItemCard" key={index}>
            <div className="ListingImg">
              <img src={item?.imgUrl?.fields?.file?.url} alt={item.name} />
            </div>
            <div className="ListingStorecc">
              <p><b>{item.name}</b></p>
            </div>
            <div className="ListingDetails">
              <div className="ListingName">
                <p>{item.title}</p>
              </div>
              <div className="ListingPrice">
                <p>{`ZMK ${item.price}`}</p>
              </div>
            </div>
            <div className="Radar">
              <p><FontAwesomeIcon className="radarFontIcon" icon={faMapMarker} />{item.location}</p>
            </div>
          </div>
        </div>
      </Link>
    </motion.li>
  ))

                            
                            :
                            
        
        //CASE API LOAD FAILS
        loadholder
        .map((item,index) => {

            return(
          <motion.li  onClick={()=>{console.log("have been clicke",item)}}  key={index}>

                           
                {/* <Link className="textLink" to={`/productOrder/${item.id}`}>  */}
                   <div className="ListingDisplayCover">
                      
                         
                            

                                    <div className="ListingItemCard" key={index}>

                                            <div className="ListingImg">

                                                   
                                                    {/* <img src={item?.image?.fields?.file?.url} /> */}
                                                    <Skeleton className="ListingImgSec" />             

                                            </div>
                                            <div className="ListingStore"> 

                                                    {/* <p><b>{item?.category}</b></p> */}

                                            </div>

                                            <Skeleton count={4}  />
                                        

                                    </div>


                            
                          
                            
                         

                </div>
                {/* </Link> */}
                        
          
          </motion.li>

                            )}) //ennd of else             
        }
      </motion.ul>
      </div>
    </>
  );
}


// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
