import Landing from "./Pages/Landing/Landing";

import {Routes,Route,Navigate, BrowserRouter} from 'react-router-dom';
//import {HashRouter as Router} from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom';
import Delivery from "./Pages/Delivery/Delivery";
import Business from "./Pages/Business/Business";
import Order from "./Pages/Order/Order";
import MapsDemo from "./Pages/MapsDemo/MapsDem";
import Login from "./Components/Login/Login";
import RequireAuth from "./Components/RequireAuth/RequireAuth";
import AdminView from "./Components/AdminView/AdminView";
import ProductOrder from "./Pages/ProductOrder/ProductOrder";
import { useState } from "react";
import Recovery from "../src/Pages/Recovery/Recovery"
import Student from "./Pages/Students/Students";
import CourierDash from "./Components/Applicants/Applicants";
import Pay from "./Pages/Pay/Pay";
// import AdminDB from "../../ntumai/src/Pages/AdminDB/AdminDB"
import AdminDB from "./Pages/AdminDB/AdminDB"
// import Applicants from "../src/Components/Applicants/Applicants"
import Applicants from "./Components/Applicants/Applicants"
import Cart from "./Pages/Cart/Cart";


import ReactGA from "react-ga4";




// import const [first, setfirst] = useState(second)
// RequireAuth



function App() {

  ReactGA.initialize("G-Z553327FM1'");

  const ROLES = {
    "admin" :"admin",
    "user" :"user",
  }

  const [cartItem,setCartItem] = useState([])
  const [orderState, setOrderState] = useState(false)

  // const handleCart = (item) =>{

  //   console.log("uplift",cartItem)

  //   setCartItem([...cartItem,item])

  //   console.log("uplift2",cartItem)

  // }

  //Manages the order state on the order page
  const handleOrderState = () =>{

    setOrderState(true)
    console.log("In order state function")
  }


  // Handles orders from the product page
  const handleCart = (item,count) => {
    console.log("uplift dated", cartItem);
  
    setCartItem((prevCart) => {
      const updatedCart = [...prevCart, 

        {
          Price: item?.price,
          count: count,
          Name: item?.title,
          location: item?.location,
          ID: item?.id,

        },
          
      ];
      // const updatedCart = [...prevCart, item];
      console.log("uplift2", updatedCart);
      return updatedCart;
    });
  };

  return (
    <div className="App">
         <Router> 

        <Routes>
            <Route path='/'  element={ <Landing handleOrderState = {handleOrderState} />}>
               
                 
            </Route> 
            <Route path='/Delivery'  element={<Delivery/>}>
               
            </Route> 
            <Route path='/Pay'  element={<Pay/>}>
               
            </Route> 
            <Route path='/pipo'  element={<Student/>}>
               
            </Route> 
            <Route path='/dash'  element={<CourierDash/>}>
               
            </Route> 
            <Route path='/Business/:BID'  element={<Business/>}>
               
            </Route> 
            <Route path='/Recovery/:token'  element={<Recovery/>}>
               
            </Route> 

            <Route path="/ProductOrder/:PID"  element={<ProductOrder handleCart={handleCart}/>}>

            </Route>

            {/* <Route element={<RequireAuth allowedRoles={[ROLES.user]} />}> */}
            <Route >
            <Route path='/adb'  element={<AdminView/>}>
               
            </Route> 
            </Route>
            
            <Route element={<RequireAuth allowedRoles={[ROLES.user,ROLES.admin]} />}>
             <Route path='/Order' 
             
                      element={<Order orderState ={orderState} Location={"Chilanga Post Office, Chilanga, Zambia"} cartItem= {cartItem}  />}>  
               
               </Route> 
              
            </Route>
            
            <Route path='/Login'  element={<Login/>}>
               
            </Route> 
            <Route path='/MapsDemo'  element={<MapsDemo/>}>
               
            </Route> 
            {/* <Route path='/OurLearning'  element={<OurLearning/>}>
           
            </Route> 
            <Route path='/Admission'  element={<Admission/>}>
                   <Route index element={<OValues />} />
                  <Route index path="AdmnProcess" element={<OValues />} />  
                  <Route path="Fees" element={<Fees />} />
                  <Route path="ApplyNow" element={<Secondary />} />
                  
           
            </Route>  */}

            {/* <Route  element={<Landing />} /> */}

            <Route path="/Admin" element={<AdminDB/>}>
                                          {/* <Route path="/Admin/students" element={<Student/>}>
                                            
                                          </Route> */}
                                          <Route path="/Admin/Applicants" element={<Applicants/>}>
                                            
                                          </Route>
                                          {/* <Route path="/Admin/Courses" element={<Coursesz/>}>
                                            
                                          </Route>
                                          <Route path="/Admin/Intakes" element={<Intakes/>}>
                                            
                                          </Route> */}
                                           <Route path='/Admin/orders'  element={<AdminView/>}>
               
                                            </Route> 
                                        
                                      </Route>

                        

                                      <Route 
            path='/cart' 
            element={<Cart cartItems={cartItem} setCartItems={setCartItem} />} 
          />

        </Routes>
    </Router>
         
           {/* <Landing/> */}
      
    </div>
  );
}

export default App;
